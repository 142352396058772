@import 'src/styles/mixins';
@import 'src/styles/variables';

.loanSummary {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  gap: 12px;

  &::before {
    content: '=';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $black6;
    border: 1px solid $black10;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 50%;
    font-size: 24px;
  }

  .summaryCard {
    @include verticalGap(16px);
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 16px;
    box-shadow: $deepBoxShadow;
    border: 1px solid $black10;
    background-color: $white;

    @include sm {
      padding: 16px 24px;
    }

    &.centerAligned {
      justify-content: center;
    }

    .summaryText {
      @include body-text(md);
      text-align: center;
      line-height: 21px;

      .highlighted {
        @include body-text(md, $semiBoldFontWeight);
      }
    }

    .summaryCheck {
      display: flex;

      .checkIcon {
        width: 11px;
        height: 11px;
        margin-right: 4px;
        margin-top: 6px;

        path {
          stroke: $green;
        }
      }

      .summaryTextContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        .checkText {
          display: flex;
          align-items: center;
          text-align: center;

          @include body-text(md);
        }

        .checkAmount {
          @include body-text(md, $semiBoldFontWeight);
          line-height: 24px;
        }
      }
    }
  }
}
