@import 'src/styles/variables';
@import 'src/styles/mixins';

.titleHighlight {
  color: $purple400;
}

.subtext {
  color: $black80;
  text-align: center;
  margin: 0 8px;
  @include body-text('md');
}
