@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: inline-flex;
}

.number {
  min-width: 52px;
}

.loaderContainer {
  margin: 0 8px;
}

.stepper {
  display: flex;
  margin: 2px 6px;

  .button {
    width: 16px;
    height: 16px;

    cursor: pointer;

    &:first-child {
      margin-right: 6px;
    }

    &.inactive {
      cursor: not-allowed;

      rect {
        stroke: $black40;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
