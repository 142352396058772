@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include stepsContainer;
  text-align: center;
  z-index: $contentIndex;
}

.title {
  @include h1;
  margin-bottom: 8px;
  text-align: center;

  @include sm {
    margin-bottom: 16px;
  }
}

.discountSection {
  margin-bottom: 36px;
}

.loanAmount {
  font-size: 56px;
  font-weight: 700;
  color: $purple400;
}

.loanAmountTitle {
  font-size: 14px;
  line-height: 24px;
  color: $black80;
  text-align: center;

  @include sm {
    margin-bottom: 4px;
  }
}

.loanAmountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.loanTermsContainer {
  @include verticalGap(8px);
}

.loanTermsTitle {
  font-weight: $boldFontWeight;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: $black100;
}

.loanTermsTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
  margin-bottom: 8px;
  text-align: left;

  @include sm {
    margin-bottom: 4px;
  }
}

.anchorButton {
  margin: 32px auto 0;
}

.button {
  margin: 40px auto 0;
  display: block;
  width: 100%;
}

.buttonMarginTop {
  margin-top: 20px;
}

.term {
  position: relative;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  @include sm {
    margin-bottom: 4px;
  }
}

.termDiscount {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #e8e8fd;
  box-shadow: 0 0 0 4px #e8e8fd;
  margin-bottom: 8px;
}

.termTitle {
  font-size: 16px;
  line-height: 24px;
  color: $black60;

  &.clickable {
    cursor: pointer;
  }
}

.termValue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.termValueStrike {
  font-weight: normal;
  text-decoration: line-through;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.loanBreakdownContainer {
  margin-top: 36px;
  @include verticalGap(8px);
}

.checkboxesContainer {
  margin-top: 24px;
}

.checkbox {
  text-align: left;
  margin-bottom: 12px;

  & span {
    font-size: 16px !important;
  }
}
