@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include offerContainer(true);
  color: $black100;
  margin-left: -16px;
  box-sizing: content-box;
  width: 100%;

  @include sm {
    margin: 0 auto;
    padding: 40px 60px 32px;
  }
}

.title {
  @include h1;
  text-align: center;
  margin-bottom: 24px;
}

.heading {
  @include body-text('lg', 700);
  margin-bottom: 12px;
  text-align: left;
}

.compareSavingsContainer {
  display: flex;
  flex-direction: column;
  text-align: center;

  .summaryWrapper {
    width: 100%;
    position: relative;

    .vs {
      z-index: 1;
      position: absolute;
      top: 36px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $black6;
      color: $black80;
      border: 1px solid $black10;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      font-weight: $mediumFontWeight;
    }
  }

  .loanSummaryContainer {
    width: 100%;
    margin-top: 12px;
  }
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  font-weight: 700;
}

.nextButton {
  margin-top: 24px;
  background-color: $primaryColor;
}

.buttonMarginTop {
  margin-top: 20px;
}

.makeChanges {
  margin-top: 36px;
  text-align: center;

  .changesLabel {
    @include body-text(md, 400);

    &:first-child {
      margin-bottom: 8px;
    }
  }

  .textUsLabel {
    @include body-text(md, 400);
    display: inline;
  }

  .contactUs {
    @include body-text(md, 500);
    color: $primaryColor;
    text-decoration: none;
  }
}
