@import 'src/styles/variables';
@import 'src/styles/mixins';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.additionalFundsContainer {
  margin-bottom: 32px;

  .question {
    margin-bottom: 16px;
  }
}

.note {
  @include body-text('md', 400);
  margin: 16px 0;
}
