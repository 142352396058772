@import 'src/styles/variables';
@import 'src/styles/mixins';

.diagram {
  display: flex;
  flex-direction: column;

  .loanPart:last-child {
    background-color: $blueLight;

    path {
      fill: $blue;
    }
  }

  .loanPart:first-child:not(:last-child) {
    background-color: $yellowLight;

    path {
      fill: $yellow;
    }
  }

  .loanPart:nth-child(2):not(:last-child) {
    background-color: $greenLight;

    path {
      fill: $green;
    }
  }
}

.arrow {
  margin: -8px auto -2px;
}

.arrowDouble {
  margin: -12px auto -8px;

  path:first-child {
    stroke: $yellowLight;
  }

  path:nth-child(2) {
    stroke: $blueLight;
  }

  @include sm {
    transform: scaleX(2.4);

    path {
      stroke-width: 10px;
    }

    path:last-child {
      transform: scaleY(1.22) scaleX(0.7) translateX(47px);
    }
  }
}

.arrowTriple {
  margin: -12px auto -8px;

  @include sm {
    transform: scaleX(2.4);

    path {
      stroke-width: 10px;
    }

    path:last-child {
      transform: scaleY(1.22) scaleX(0.7) translateX(47px);
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.consolidate {
  background-color: $greenLight;
}

.consolidateArrow path {
  fill: linear-gradient(0deg, $greenLight, $irisLight);
}

.term {
  background-color: $irisLight;
}

.container {
  @include stepsContainer;
  @include sm() {
    margin-top: 20px;
  }

  .buttonMarginTop {
    margin-top: 20px;
  }
}
