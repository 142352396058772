@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include verticalGap(16px);
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.radio {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid $black10;
  border-radius: 50%;

  &.small {
    width: 12px;
    height: 12px;
    margin: 2px 0;

    &.selected {
      width: 6px;
      height: 6px;
      border: 5px solid $primaryColor;
    }
  }
}

.radio.selected {
  width: 8px;
  height: 8px;
  border: 6px solid $primaryColor;
}
