@import '../../styles/mixins';

.error {
  color: $red;
  text-align: center;
  margin: 0 auto;
  font-weight: $semiBoldFontWeight;
  display: flex;
  gap: 8px;
}

.buttonContainer {
  @include verticalGap(20px);
}
