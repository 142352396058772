@import 'src/styles/mixins';
@import 'src/styles/variables';

.inputSelectContainer {
  position: relative;
  caret-color: transparent;
}

.selectArrow {
  position: absolute;
  top: 24px;
  right: 16px;
  width: 16px;
  height: 16px;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  transform: translateY(-50%);
  transform-origin: center;

  @include sm {
    right: 24px;
  }
}

.selectArrowActive {
  transition: 0.2s ease-in-out;
  transform: translateY(-50%) rotate(180deg);
}

.dropdownOptionsList {
  @include dropdownOptionsList;
}

.dropdownOptionContainer {
  position: relative;
}

.dropdownOption {
  padding: 16px 46px 16px 16px;
  color: $black100;
  cursor: pointer;

  font-size: 16px;
  line-height: 28px;

  &:hover {
    background: $black3;
  }
}

.checkIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  pointer-events: none;
  transform: translateY(-50%);
}

.label {
  @include inputLabel;
}
