@import 'src/styles/mixins';
@import 'src/styles/variables';

.term {
  position: relative;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  @include sm {
    margin-bottom: 4px;
  }
}

.termDiscount {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #e8e8fd;
  box-shadow: 0 0 0 4px #e8e8fd;
  margin-bottom: 8px;
}

.termTitle {
  font-size: 16px;
  line-height: 24px;
  color: $black60;

  &.clickable {
    cursor: pointer;
  }
}

.termValue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.termValueStrike {
  font-weight: normal;
  text-decoration: line-through;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.paymentOptions {
  z-index: 100;
  position: absolute;
  top: 24px;

  padding: 24px;

  border-radius: 24px;

  background-color: $white;
  /* For Screen */
  box-shadow: $otherBoxShadow;

  color: $black60;
  text-align: left;

  p {
    margin-bottom: 24px;
    line-height: 172%;
  }

  .firstButton {
    margin-bottom: 20px;
  }
}

.fullSize {
  margin-top: 16px;
  width: 100%;
}
